import { useStaticQuery, graphql } from "gatsby"

export const useTranslation = (string, lang) => {
  const {
    allGoogleTranslationsSheet: { nodes },
  } = useStaticQuery(graphql`
    {
      allGoogleTranslationsSheet {
        nodes {
          eN
          fR
          id
        }
      }
    }
  `)

  const item = nodes?.find?.(node => node.eN === string)

  const langKey = `${lang?.[0]}${lang?.toUpperCase()?.slice?.(1)}`

  return item?.[langKey] || string
}

export default useTranslation
