import React from "react"
import { graphql } from "gatsby"
import classNames from "classnames"

import Seo from "../components/Seo"
import useTranslation from "../hooks/useTranslation"
import PageWrapper from "../components/PageWrapper"

const ModelSinglePage = props => {
  const {
    pageContext: { name, brand, lang },
    data: {
      allGoogleFastenerFinderSheet: { nodes },
    },
  } = props

  const isBissettSku = nodes.some(node => node.bISSETTSKU)
  const isLength = nodes.some(node => node.lENGTH)
  const isShank = nodes.some(node => node.sHANK)
  const isGauge = nodes.some(node => node.gAUGE)
  const isCrown = nodes.some(node => node.cROWN)
  const isType = nodes.some(node => node.tYPE)
  const isDescription = nodes.some(node => node.dESCRIPTION)
  const isQty = nodes.some(node => node.qTY)

  const length = useTranslation("LENGTH", lang)
  const gauge = useTranslation("GAUGE", lang)
  const description = useTranslation("DESCRIPTION", lang)
  const qty = useTranslation("QTY", lang)
  const crown = useTranslation("CROWN", lang)
  const shank = useTranslation("SHANK", lang)
  const type = useTranslation("TYPE", lang)

  return (
    <PageWrapper>
      <Seo title={`${brand} ${name}`} lang={lang} />

      <main className="flex-grow mx-auto  w-full flex flex-col px-4 sm:px-6 lg:px-8">
        <h5 className="mt-[60px] mr-[30px] mb-[30px] text-[1.2rem] font-bold text-white tracking-tight">
          {brand} {name}
        </h5>
        <div className="flex-shrink-0 py-4 sm:py-8">
          <div className="w-full">
            <table className="text-[1.2rem] leading-[30px] text-left w-full max-w-full prose border-collapse  text-white border-t border-l border-b border-solid border-white">
              <tbody>
                <tr>
                  {isBissettSku && <Th>BISSETT SKU</Th>}
                  {isLength && <Th>{length}</Th>}
                  {isShank && <Th>{shank}</Th>}
                  {isGauge && <Th>{gauge}</Th>}
                  {isCrown && <Th>{crown}</Th>}
                  {isType && <Th>{type}</Th>}
                  {isDescription && <Th>{description}</Th>}
                  {isQty && <Th>{qty}</Th>}
                </tr>

                {nodes?.map?.(row => {
                  return (
                    <tr key={row.id}>
                      {isBissettSku && <Td>{row.bISSETTSKU}</Td>}
                      {isLength && <Td>{row.lENGTH}</Td>}
                      {isShank && <Td>{row.sHANK}</Td>}
                      {isGauge && <Td>{row.gAUGE}</Td>}
                      {isCrown && <Td>{row.cROWN}</Td>}
                      {isType && <Td>{row.tYPE}</Td>}
                      {isDescription && <Td>{row.dESCRIPTION}</Td>}
                      {isQty && <Td>{row.qTY}</Td>}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </PageWrapper>
  )
}

export default ModelSinglePage

const Td = ({
  children,
  className,
}: {
  children?: any
  className?: string
}) => {
  return (
    <td className={classNames("p-4 border-r border-white", className)}>
      {children}
    </td>
  )
}
const Th = ({
  children,
  className,
}: {
  children?: any
  className?: string
}) => {
  return (
    <th className={classNames("p-4 border-r border-white", className)}>
      {children}
    </th>
  )
}

export const modelQuery = graphql`
  query modelQuery($name: String!) {
    allGoogleFastenerFinderSheet(filter: { mODEL: { eq: $name } }) {
      nodes {
        id
        bISSETTSKU
        bRAND
        cROWN
        dESCRIPTION
        gAUGE
        mODEL
        lENGTH
        qTY
        sHANK
        tYPE
      }
    }
  }
`
